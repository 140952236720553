.page-header {
    background-color: black;
    min-height: 10rem;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5rem;
}

.page-header nav {
    display: flex;
}

.page-header nav a {
    color: #faf0f0;

    transition: 0.3s;

    border-top: 1px solid black;
    border-bottom: 1px solid black;

    padding: 0.5rem 0;
    margin-right: 1rem;
}

.page-header nav a:hover {
    color: #dea443;
    border-color: #dea443;
}

.page-header img:first-child {
width: 4.5rem
}
.page-header img:last-child {
width: 12rem;
}

@media (max-width: 700px) {
    .page-header {
        justify-content: center;
    }
    .page-header img:first-child {
        width: 5.7rem;
        }
    .page-header img:last-child {
        width: 15rem;
        }
    .page-header nav  {
        display: none;
    }
    .page-header div:last-child {
        display: none;
    }
    
}