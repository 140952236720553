#page-home {
    width: 100%;
    height: 100%;

}

#page-home main {
    width: 100%;
    overflow: hidden;
}

.slider {
    display: flex;
    background-color: white;
    height: 45rem;
}

.slider-holder {
    width: 100vw;
    flex: none;
    animation: slide 12s infinite;
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(0);
    }
    30% {
        transform: translateX(-100vw);
    }
    55% {
        transform: translateX(-100vw);
    }
    60% {
        transform: translateX(-200vw);
    }
    95%{
        transform: translateX(-200vw);
    }
  }

.slider-holder img {
    width: 100%;
    height: 100%;
}

.nav-banners {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background-color: white;

    padding: 3rem 0 6rem 0;
}

.nav-banners h1 {
    color: black;
    font-size: 5rem;
}
.nav-holder {
    display: flex;
    flex-direction: column;
}

.nav-holder > a {
    width: 80vw;
    height: 30rem;
    background-color: white;

    border-radius: 2rem;

    box-shadow: 1rem 1.3rem 1.5rem rgba(0, 0, 0, 0.4);

    margin-top: 3rem;
    overflow: hidden;

}

.nav-holder > a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(100%);

    transition: 0.7s;
}

.nav-holder > a img:hover {
    transform: scale(1.1);
}

.nav-holder > a span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.nav-holder > a:hover {
    color: white;
}

.quem-somos {
    background-color: black;
    color: white;

    height: 100vh;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5rem;
}

.quem-somos section {
    text-align: center;
    font-size: 20px;
    padding-left: 2rem;
}

.quem-somos img {
    width: 55rem;
    border-radius: 1rem;
}

.quem-somos section h1 {
    font-size: 5rem;
    color: #dea443;
}

@media(max-width: 700px) {
    .slider {
        height: 19rem;
    }

    .nav-holder {
        flex-direction: column;
    }

    .nav-holder > a {
        width: 35rem;
        height: 13rem;
    }

    .nav-holder > a img {
        object-fit: cover;
    }

    .quem-somos {
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        padding-top: 5rem;
        padding-bottom: 10rem;

        font-size: 90%;
    }
    .quem-somos img {
        width: 30rem;
        margin-bottom: 2rem;
    }
    .produto {
        width: 14rem;
        height: 15rem;
    }
    .produto img {
        max-width: 12rem;
    }
}