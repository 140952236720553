html {
    font-size: 70%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body, #root {
    height: 100vh;
}

body {
    background: #f0f0f7;
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
    
}

body,
input,
button {
    font: 400 16px Lato, sans-serif;
    border: 0;
}

a {
    text-decoration: none;
}

h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

.container {
    width: 90vw;
    max-width: 700px;
}

@media (max-width: 700px) {
    html {
        font-size: 62.5%;
    }
}