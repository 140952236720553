.page-footer {
    background-color: #282d32;
    min-height: 10rem;
    width: 100%;

    display: flex;
    justify-content: space-around;

    padding: 5rem 2rem;

    overflow: hidden;
}

.page-footer > section {
    text-align: center;
    max-width: 30rem;

    margin: 0 2rem;

    font-size: 80%;
}

.page-footer section > h1 {
    color: #dea443;
}
.page-footer section > p {
    color: white;
}
.page-footer section > nav {
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-footer section > p > a {
    color: white;
}

.social-links {
    border-radius: 50%;
    border-color: white;
    color: white;
    border: 0.2rem solid;

    padding: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;
}

@media (max-width: 700px) {
    .page-footer {
        padding: 2rem 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .page-footer img {
        border-radius: 50%;
        padding-bottom: 2rem;
    }
    .page-footer > section {
        padding-bottom: 1rem;
    }
}