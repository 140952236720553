.product-session {
    
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 5rem;
}

.product-session h1 {
  color: black;
  font-size: 5.5rem;
  text-align: center;
}

.vitrine {
  background-color: white;
  border-radius: 1rem;
  padding: 4rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem 2rem;

  border: 0.4rem solid #dea443;
}

.produto {
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0.5rem 0.5rem 0.5em rgba(0, 0, 0, 0.4);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 20rem;
  height: 21rem;

}

.produto strong {
  text-decoration: none;
  color: grey;
}

.produto div {
  overflow: hidden;
}

.foto {
  max-width: 18rem;
  height: auto;
  transition: 0.2s;
}

.foto:hover {
  transform: scale(1.25);
}

@media(max-width: 700px) {
  .vitrine {
      grid-template-columns: repeat(2, 1fr);
      margin: 0 2rem;
      padding: 2rem;
  }
  .produto {
      width: 14rem;
      height: 15rem;
  }
  .produto img {
      max-width: 12rem;
  }
}