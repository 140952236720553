#page-banner {
  width: 100%;
  height: 100%;

  
}

main {
  overflow: hidden;
  background-color: white;
}

.banner-img {
  width: 100vw;
  height: auto;
}