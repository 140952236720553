.wpp-container {
    position: fixed;
    right: 0;
    bottom: 0;
    margin-right: 1rem;
}

.wpp-container img {
    width: 6.5rem;
    height: 6.5rem;
}