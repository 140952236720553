#page-product {
    height: 100vh;
    width: 100vw;
}

#page-product main {
    display: flex;
    align-items: center;
    justify-content: center;
}
#page-product main section {
    background-color: white;

    flex-direction: column;
    border-radius: 1rem;
    display: flex;
    width: 90%;
    padding: 2rem 2rem;
}

#page-product main section h1 {
    align-self: center;
    color: white;
}

.product-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 2rem;
    align-items: center;
    justify-items: center;
}

.description-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    min-height: 34rem;
    border-radius: 1rem;
    overflow: hidden;
    border-style: solid;
    border-color: orange;
}

.description-container nav {
    background-color: orange;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-height: 5rem;
}

.nav-button {
    background-color: rgba(0, 0, 0, 0);

    padding: 1rem;
    border-radius: 1rem 1rem 0 0;
    cursor: pointer;
}

.nav-button-selected {
    background-color: white;

    padding: 1rem;
    border-radius: 1rem 1rem 0 0;
}


.nav-button:hover {
    background-color: white;
}

.description-container p {
    padding: 1rem;
}

.wpp-button {
    margin: 2rem 0;

    font-size: 2rem;
    
    background-color: #00ff00;
    color: white;
    padding: 1rem;
    border-radius: 20px;
    grid-column: 2;
    max-width: 18rem;

    transition: 0.2s;
}

.wpp-button:hover {
    background-color: green;
}

.product-image {
    display: flex;
    height: 28rem;
    width: 28rem;
}

@media(max-width: 800px) {
    .product-container {
        display: flex;
        flex-direction: column;
    }

    .description-container {
        margin-top: 5rem;
    }
    .description-container nav {
        justify-content: center;
    }
    .nav-button {
        padding: 2rem 1rem;
        outline: none;
    }
    .nav-button-selected {
        padding: 2rem 1rem;
        outline: none;
    }
}